import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { useTheme } from "styled-components";
import { useOpenPortalSnackbar } from "../../../../components/Snackbar/Snackbar";
import * as cartAPI from "@ecp-redux/api/cart";
import { IOrderLine } from "@ecp-redux/dto/cart.types";
import {
  IThemeState,
  TInputId,
  TSnackbarId,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { isErrorByCode } from "../../../../../helpers/helpers";
import { useTimeoutSignal } from "../../../../../helpers/useTimeoutSignal";
import { convertSnackbarIdToValues } from "../../../../../settingsPatterns/settingsPatterns.methods";
import { ReactComponent as MinusIcon } from "../../../../icons/minus.svg";
import { ReactComponent as PlusIcon } from "../../../../icons/plus.svg";
import { StyledInput } from "../../../Input/StyledInput/StyledInput";
import { useCart } from "../cart.methods";
import { StyledChangeProductQuantity } from "./StyledChangeProductQuantity.styled";
import {
  eventAddToCart,
  eventRemoveFromCart,
} from "../../../../../boxes/analytics/events";
import useSetSessionStorage from "../../../../../shared/hooks/useSetSessionStorage";
import { TEventOrigin } from "../../../../../boxes/analytics/const";

interface IChangeProductQuantityProps {
  product: IOrderLine;
  inputQuantityStyle: TInputId;
  errorSnackbarStyle: TSnackbarId;
  className: string;
  boxType: TEventOrigin;
  disabled?: boolean;
  successSnackbarStyle?: TSnackbarId;
  successSnackbarMessage?: string;
}

interface IProductDelta {
  productSku: string;
  quantity: number;
}

const ChangeProductQuantity: React.FC<IChangeProductQuantityProps> = ({
  product,
  inputQuantityStyle,
  errorSnackbarStyle,
  disabled,
  boxType,
  className,
  successSnackbarStyle,
  successSnackbarMessage,
}) => {
  const theme = useTheme() as IThemeState;
  const snackbarDisplayTime = convertSnackbarIdToValues(
    errorSnackbarStyle,
    theme.globalObjects.snackbars
  ).timeToDisplay;
  const { getSessionStorageItem } = useSetSessionStorage();
  const customer_id =
    parseInt(getSessionStorageItem("customer_id") as string) || null;
  const [putQuantity, quantity] = cartAPI.usePutChangeProductCountMutation();
  const [quantityState, setQuantityState] = useState(product.quantity);
  const temporaryError = useTimeoutSignal(
    isErrorByCode(quantity, [2004, 2005, 2012, 2014, 2015]),
    snackbarDisplayTime * 1000
  );
  const { openSnackbarWithCartError } = useCart();
  const { openPortalSnackbar } = useOpenPortalSnackbar();
  const changeProductQuantity = async (productDelta: IProductDelta) => {
    await putQuantity(productDelta)
      .unwrap()
      .then(() => {
        productDelta.quantity === 1 &&
          successSnackbarStyle &&
          successSnackbarMessage &&
          openPortalSnackbar(successSnackbarStyle, successSnackbarMessage);

        const actionInCartEventObject = {
          product_id: product.productSku,
          customer_id: customer_id,
          quantity: productDelta.quantity,
          currency: theme.advanceSettings.messages.currencyShort,
          sales_price: product.salePrice,
          net_sales_price: product.nettoPrice,
          base_price: product.price,
          suggested_retail_price: product.suggestedRetailPrice,
          origin: boxType,
        };

        if (productDelta.quantity > 0) eventAddToCart(actionInCartEventObject);

        if (productDelta.quantity < 0)
          eventRemoveFromCart({
            ...actionInCartEventObject,
            quantity: Math.abs(productDelta.quantity),
          });
      })
      .catch((e) => {
        openSnackbarWithCartError(e);

        if (e.data?.code === 2014) {
          const productQuantityStockLimit = Number(
            e.data?.data?.PRODUCT_CART_LIMIT_QUANTITY
          );
          putQuantity({
            productSku: productDelta.productSku,
            quantity: productQuantityStockLimit - product.quantity,
          });

          if (
            productQuantityStockLimit - product.quantity > 0 &&
            productDelta.quantity > 0
          )
            eventAddToCart({
              product_id: product.productSku,
              customer_id: customer_id,
              quantity: productQuantityStockLimit - product.quantity,
              currency: theme.advanceSettings.messages.currencyShort,
              sales_price: product.salePrice,
              net_sales_price: product.nettoPrice,
              base_price: product.price,
              suggested_retail_price: product.suggestedRetailPrice,
              origin: boxType,
            });
        }

        if (e.data?.code === 2015) {
          const productQuantityStockLimit = Number(
            e.data?.data?.PRODUCT_QUANTITY_STOCK_LIMIT
          );
          putQuantity({
            productSku: productDelta.productSku,
            quantity: productQuantityStockLimit - product.quantity,
          });

          if (
            productQuantityStockLimit - product.quantity > 0 &&
            productDelta.quantity > 0
          )
            eventAddToCart({
              product_id: product.productSku,
              customer_id: customer_id,
              quantity: productQuantityStockLimit - product.quantity,
              currency: theme.advanceSettings.messages.currencyShort,
              sales_price: product.salePrice,
              net_sales_price: product.nettoPrice,
              base_price: product.price,
              suggested_retail_price: product.suggestedRetailPrice,
              origin: boxType,
            });
        }
      });
  };

  const debouncedChangeQuantity = debounce(
    async (quantity: number) =>
      await changeProductQuantity({ productSku: product.productSku, quantity }),
    500
  );

  const changeQuantityHandler = useCallback(
    (val: number) => {
      if (!isNaN(val)) {
        setQuantityState(product.quantity + val);
        debouncedChangeQuantity(val);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [product.quantity]
  );

  useEffect(() => {
    setQuantityState(product.quantity);
  }, [product.quantity]);

  return (
    <StyledChangeProductQuantity className={className} disabled={disabled}>
      <button
        className={className + "__minus-button"}
        onClick={() =>
          changeProductQuantity({
            productSku: product.productSku,
            quantity: -1,
          })
        }
        disabled={product.quantity === 1 || quantity.isLoading}
      >
        <MinusIcon />
      </button>
      <StyledInput
        className={className + "__count-input"}
        $settings={inputQuantityStyle}
        value={quantityState}
        onChange={(e) =>
          changeQuantityHandler(~~e.target.value - product.quantity)
        }
        data-testid="productCountInput"
        formNoValidate={!temporaryError}
      />
      <button
        className={className + "__plus-button"}
        onClick={() =>
          changeProductQuantity({
            productSku: product.productSku,
            quantity: 1,
          })
        }
        disabled={temporaryError || quantity.isLoading}
        data-testid="incrementBtn"
      >
        <PlusIcon />
      </button>
    </StyledChangeProductQuantity>
  );
};

export default ChangeProductQuantity;
