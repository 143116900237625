import {
  IBoxAddToCartMessages,
  IBoxAddToCartSettings,
} from "../../../../boxes/BoxAddToCart/BoxAddToCart.types";
import {
  IBoxMiniCartMessages,
  IBoxMiniCartSettings,
} from "../../../../boxes/BoxMiniCart/BoxMiniCart.types";
import {
  IBoxProductSliderMessages,
  IBoxProductSliderSettings,
} from "../../../../boxes/BoxProductSlider/BoxProductSlider.types";
import {
  IBoxSearchResultsMessages,
  IBoxSearchResultsSettings,
} from "../../../../boxes/BoxSearchResults/BoxSearchResults.types";
import {
  IBoxCartStepOneMessages,
  IBoxCartStepOneSettings,
} from "../../../../boxes/Cart/BoxCartStepOne/BoxCartStepOne.types";
import { isErrorByCode } from "../../../../helpers/helpers";
import { useMessagesSettingsContext } from "../../../../structure/Contexts/MessagesSettingsContext";
import { useOpenPortalSnackbar } from "../../Snackbar/Snackbar";

type TSettings =
  | IBoxProductSliderSettings
  | IBoxSearchResultsSettings
  | IBoxAddToCartSettings
  | IBoxMiniCartSettings
  | IBoxCartStepOneSettings;

type TMessages =
  | IBoxProductSliderMessages
  | IBoxSearchResultsMessages
  | IBoxAddToCartMessages
  | IBoxMiniCartMessages
  | IBoxCartStepOneMessages;

export const useCart = () => {
  const { messages, settings } = useMessagesSettingsContext<
    TMessages,
    TSettings
  >();
  const { openPortalSnackbar } = useOpenPortalSnackbar();

  const openSnackbarWithCartError = (resp: any): void => {
    if (isErrorByCode(resp, [2004])) {
      openPortalSnackbar(
        settings.add_to_cart_error_snackbar,
        messages.product_error_limit_exceeded
      );
    } else if (isErrorByCode(resp, [2005, 2012, 2014])) {
      openPortalSnackbar(
        settings.add_to_cart_error_snackbar,
        messages.cart_quantity_limit_exceeded_warning
      );
    } else if (isErrorByCode(resp, [2015])) {
      openPortalSnackbar(
        settings.add_to_cart_error_snackbar,
        messages.product_error_stock_exceeded
      );
    } else if (isErrorByCode(resp, [2002])) {
      openPortalSnackbar(
        settings.add_to_cart_error_snackbar,
        messages.product_not_available_error
      );
    } else {
      openPortalSnackbar(
        settings.add_to_cart_error_snackbar,
        messages.add_to_cart_error_message
      );
    }
  };

  return { openSnackbarWithCartError };
};
