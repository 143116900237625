import styled from "styled-components";

export const StyledChangeProductQuantity = styled.div<{
  className: string;
  disabled: boolean | undefined;
}>`
  display: flex;
  align-self: flex-end;

  opacity: ${(props) => (props.disabled ? "0.2" : "1")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  .${(props) => props.className}__minus-button {
    padding: 8px;

    &:hover {
      background-color: #eeeeee;
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .${(props) => props.className}__plus-button {
    padding: 8px;

    &:hover {
      background-color: #eeeeee;
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .${(props) => props.className}__count-input {
    text-align: center;
    max-width: 50px;
    padding-right: 10px;
  }
`;
